import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import AliceCarousel from 'react-alice-carousel';
import logoEntreprise from '../assets/logoentreprise.png';
import backgroundCard from '../assets/background-card-challenge.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faFlag } from '@fortawesome/pro-regular-svg-icons';
import { faBullseyeArrow, faUserCheck, faUsers } from '@fortawesome/pro-light-svg-icons';

import EntrepriseService from '../services/EntrepriseService';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';

import { ICompany } from '../interfaces/ICompany';
import { IChallenge } from '../interfaces/IChallenge';
import { PropagateLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';

let BASE_URL: any;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    BASE_URL = process.env.REACT_APP_API_URL_DEV;
} else {
    BASE_URL = process.env.REACT_APP_API_URL_PROD;
}

const EntrepriseScreen: React.FC = () => {
    const [token, setToken] = useState('');
    const { getItem } = useAsyncStorage('token');
    const [company, setCompany] = useState<ICompany | null>(null);

    const [userCount, setUserCount] = useState(0);
    const [teamsCount, setTeamsCount] = useState(0);
    const [tasksCount, setTasksCount] = useState(0);
    const [challengesCount, setChallengesCount] = useState(0);

    const [challenge, setChallenge] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();

    useEffect(() => {
        const getToken = async () => {
            try {
                const savedToken = await getItem();
                if (savedToken !== null) {
                    setToken(savedToken);
                    console.log('Token loaded :', savedToken);
                }
            } catch (error) {
                console.error('Error loading token from AsyncStorage:', error);
            }
        };

        getToken();
    }, []);

    useEffect(() => {
        if (!token) return;

        EntrepriseService.getCompany(token).then((data: any) => {
            const id = data._id;
            setLoading(false);

            EntrepriseService.getCompanyUsers(token, id).then((users: any) => {
                setUserCount(users.length);
                setLoading(false);
            }).catch((error: any) => {
                console.error('Error fetching users:', error);
            });

            EntrepriseService.getCompanyTeams(token, id).then((teams: any) => {
                setTeamsCount(teams.length);
                setLoading(false);
            }).catch((error: any) => {
                console.error('Error fetching teams:', error);
            });

            EntrepriseService.getCompanyChallenges(token, id).then((challenges: any[]) => {
                console.log('Challenges:', challenges);
                setChallenge(challenges);
                setChallengesCount(challenges.length);
                setTasksCount(challenges.reduce((acc, challenge) => acc + challenge.tasks.length, 0));
                setLoading(false);
            }).catch((error: any) => {
                console.error('Error fetching challenges:', error);
            });

            setCompany(data);
        }).catch((error: any) => {
            console.error('Error fetching challenges:', error);
        });
    }, [token]);

    useEffect(() => {
        console.log("company picture url", `${BASE_URL}/ressources/companies/${company?.picture}`)
    }, [company])

    if (loading) {
        return (
            <div className="container-loading">
                <PropagateLoader color="#4F12B8" />
            </div>
        );
    }

    const ChallengesCarrousel = challenge.map((challengeItem, index) => (
        <div className='card-challenge-container' key={index}>
            <img src={`${BASE_URL}/ressources/challenges/${challengeItem.local.fr.picture}`} alt="" className='background-card-challenge' />
            <h3>{challengeItem.local.fr.name}</h3>
            <div className='info-card-challenge-container'>
                <p className='nombre-inscrit-challenge'>{challengeItem.registred} {t("inscrits")}</p>
                <div className='date-card-challenge-container'>
                    <FontAwesomeIcon icon={faClock} />
                    <p>{challengeItem.dayLeft} {t("jours-restants")}</p>
                </div>
            </div>
        </div>
    ));

    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 2 },
        1444: { items: 3 },
    };

    return (
        <div className='container'>
            <Header title={t("entreprise")} />
            <div className='header-entreprise-container item-container'>
                <img src={company?.picture ? `${BASE_URL}/ressources/companies/${company?.picture}` : `${BASE_URL}/ressources/companies/fakecompany.png`} alt="" className='image-entreprise' />
                <p>{company?.name}</p>
            </div>

            <div className='all-card-entreprise item-container'>
                <div className='card-entreprise-container'>
                    <FontAwesomeIcon icon={faUserCheck} size='2x' className='icon-entreprise' />
                    <p className='nb-pers'>{userCount}</p>
                    <p>{t("nb-joueur-inscrit")}</p>
                </div>

                <div className='card-entreprise-container'>
                    <FontAwesomeIcon icon={faUsers} size='2x' className='icon-entreprise' />
                    <p className='nb-pers'>{teamsCount}</p>
                    <p>{t("nb-equipe")}</p>
                </div>

                <div className='card-entreprise-container'>
                    <FontAwesomeIcon icon={faFlag} size='2x' className='icon-entreprise' />
                    <p className='nb-pers'>{challengesCount}</p>
                    <p>{t("nb-challenges")}</p>
                </div>

                <div className='card-entreprise-container'>
                    <FontAwesomeIcon icon={faBullseyeArrow} size='2x' className='icon-entreprise' />
                    <p className='nb-pers'>{tasksCount}</p>
                    <p>{t("nb-defis")}</p>
                </div>
            </div>

            <div className='item-container'>
                {challenge.length > 0 ? (
                    <>
                        <h2>{t("challenge-en-cours")}</h2>
                        <div>
                            <AliceCarousel
                                items={ChallengesCarrousel}
                                responsive={responsive}
                            />
                        </div>
                    </>
                ) : (
                    <p>{t("no-challenge")}</p>
                )}
            </div>

        </div>
    );
};

export default EntrepriseScreen;
