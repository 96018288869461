import React, { useEffect, useState } from 'react';
import Header from '../components/Header';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRight, faArrowsRotate, } from '@fortawesome/pro-regular-svg-icons';
import { faMessage, faMessages, faSend } from '@fortawesome/pro-light-svg-icons';

import BarChartChat from '../components/BarChartChat';
import teamsPicture from '../assets/teams-picture.png';

import { useAsyncStorage } from '@react-native-async-storage/async-storage';

import ChatService from '../services/ChatService';
import UserService from '../services/UserService';
import EntrepriseService from '../services/EntrepriseService';

import { IUser } from '../interfaces/IUsers';
import { ITeam } from '../interfaces/ITeams';
import { IChatBase } from '../interfaces/IChat';
import { PropagateLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';

const ChatScreen: React.FC = () => {
    const [token, setToken] = useState('');
    const [stats, setStats] = useState<any>();
    const { getItem } = useAsyncStorage('token');
    const [selectedLink, setSelectedLink] = useState(3);
    const [messagesByDay, setMessagesByDay] = useState<any[]>([]);

    const [user, setUser] = useState<IUser>();
    const [teams, setTeams] = useState<ITeam[]>([]);
    const [teamChat, setTeamChat] = useState<IChatBase[]>([]);

    const [teamUsers, setTeamUsers] = useState<any[]>([]);
    const [allChat, setAllChat] = useState(0);

    const [allReaction, setAllReaction] = useState(0);

    const [loading, setLoading] = useState(true);
  
    const [refresh, setRefresh] = useState(false);
    const {t} = useTranslation();


    useEffect(() => {
        const getToken = async () => {
            try {
                const savedToken = await getItem();

                if (savedToken !== null) {
                    setToken(savedToken);
                    console.log('Token loaded :', savedToken);
                }
            } catch (error) {
                console.error('Error loading token from AsyncStorage:', error);
            }
        };
        
        getToken();
    }, []);

    useEffect(() => {
        if (!token) return;

        UserService.getUser(token).then((data: any) => {
            setUser(data);
            setLoading(false);
        }).catch((error: any) => {
            console.error('Error fetching challenges:', error);
        });

        ChatService.getStatsByPeriod(token, "thisMonth").then((stats: any) => {
            setStats(stats);
            setLoading(false);
            // console.log('Stats loaded :', stats);
            setSelectedLink(3);
        });

        ChatService.getMessagesDays(token).then((messagesByDay: any[]) => {
            setMessagesByDay(messagesByDay);
            setLoading(false);
            console.log('Messages by day loaded :', messagesByDay);
        });
    }, [token, refresh]);

    const handleLinkClick = (index: any) => {
        setSelectedLink(index);
        let period = ((index === 0) ? 'month' : (index === 1) ? 'week' : (index === 2) ? 'day' : (index === 3) ? 'all' : 'month');
        ChatService.getAllMessages(token, user?.companyId ,period).then((chats: any) => {
            setAllChat(chats);
            console.log('All chat loaded :', chats);
            setLoading(false);
        });
        ChatService.getAllReaction(token, user?.companyId,period).then((reactions: any) => {
            setAllReaction(reactions.totalReactions);
            setLoading(false);
        } );
    };

    useEffect(() => {
        if (!user) return;

        EntrepriseService.getCompanyTeams(token, user.companyId).then((teams: ITeam[]) => {
            setTeams(teams);
            setLoading(false);
            // console.log('Teams loaded :', teams);
        });
    }, [user,refresh]);


    useEffect(() => {
        const getAllChat = async (period?:any) => {
            try{
                const response = await ChatService.getAllMessages(token, user?.companyId, period);
                setAllChat(response);
            } catch (error) {
                console.error('Error fetching all chat:', error);
            }
        }

        const getAllReaction = async () => {
            try{
                const response = await ChatService.getAllReaction(token, user?.companyId);
                setAllReaction(response.totalReactions);
                  

            } catch (error) {
                console.error('Error fetching all reaction:', error);
            }
        }

        if(token && user) {
            getAllChat();
            getAllReaction();
        }
    } , [token,user]);


    if (loading) return <div className="container-loading"><PropagateLoader color="#4F12B8" /></div>;

    const handleRefresh = () => {
        setRefresh(!refresh);
    };

    return (
        <div className='container'>
            <Header title={t("chat")} />
            <div className='stats-date-container item-container'>
                <div className='stat-date-title'>
                    <h3>{t('stats-periode')}</h3>
                    <a className='refresh-icon-responsive' href="">
                        <div className=''>
                            <FontAwesomeIcon icon={faArrowsRotate} size='xl' className='ellipse-white-top' />
                        </div>
                    </a>
                </div>

                <div className='left-date-link-container'>
                    <div className={selectedLink === 0 || 1 || 2 || 3 ? "date-link-container-selected" : 'date-link-container'}>
                        <a href="#" onClick={() => handleLinkClick(3)} className={selectedLink === 3 ? 'selected-link' : 'link'}>{t("tous")}</a>
                        <a href="#" onClick={() => handleLinkClick(0)} className={selectedLink === 0 ? 'selected-link' : 'link'}>{t("ce-mois")}</a>
                        <a href="#" onClick={() => handleLinkClick(1)} className={selectedLink === 1 ? 'selected-link' : 'link'}>{t("cette-semaine")}</a>
                        <a href="#" onClick={() => handleLinkClick(2)} className={selectedLink === 2 ? 'selected-link' : 'link'}>{t("aujourdhui")}</a>
                    </div>
                    
                    <a onClick={()=>{setRefresh(!refresh)}} style={{cursor:'pointer'}}> 
                        <div className='refresh-icon'>
                            <FontAwesomeIcon icon={faArrowsRotate} size='xl' className='ellipse-white-top' />
                        </div>
                    </a>
                </div>
            </div>

            <div className='all-large-card-container item-container'>
                <div className='large-card-container'>
                    <div className='stats-logo-container'>
                        <FontAwesomeIcon icon={faSend} size='3x' className='' />
                        {/* <div className='stats-up-container'>
                                <FontAwesomeIcon icon={faArrowUpRight} />
                                <p>+3%</p>
                            </div> */}
                    </div>
                    <p className='n-large-card'>{(allChat as any).totalMessages}</p>
                    <p className='p-large-card'>{t("nb-de")}<br /> {t("message-envoye")}</p>
                </div>

                <div className='large-card-container'>
                    <div className='stats-logo-container'>
                        <FontAwesomeIcon icon={faMessages} size='3x' className='' />
                        {/* <div className='stats-up-container'>
                                <FontAwesomeIcon icon={faArrowUpRight} />
                                <p>+3%</p>
                            </div> */}
                    </div>
                    <p className='n-large-card'>{allReaction}</p>
                    <p className='p-large-card'>{t("nb-de")} <br /> {t("reaction-envoye")}</p>
                </div>
            </div>

            <div className='barChartChat-container item-container'>
                {messagesByDay ? (
                    <BarChartChat
                    width="80%"
                    height="400px"
                    title={t("nb-message-ce-mois")}
                    labels={Object.keys(messagesByDay)}
                    dataset={Object.values(messagesByDay)}
                    lineColor="#FBF7FF"
                />
                ):
                <p>{t("no-message")}</p>}
            </div>

            {/* 
            // [TODO]: add back these lines

            <div className='item-container'>
                <h3>Les chats de l'entreprise</h3>

                <div className='all-card-teams-container'>
                    {teams.map((team: ITeam) => (
                        <div className='card-teams-container' key={team._id}>
                            <div className='team-info-container'>
                                <div className='team-info'>
                                    <img src={teamsPicture} className='teams-picture-contain' alt="" />
                                    <p><b>{team.name}</b> - <b>{team.memberIds?.length}</b> participants</p>
                                </div>
                                <div className='nb-message'>
                                    <FontAwesomeIcon icon={faMessage} size='xl' className='' />
                                    <p><b>{teamChat.find(chat => chat.teamId === team._id)?.messages.length || 0}</b> messages</p>
                                </div>
                            </div>

                            <div className='labels-container'>
                                {teamUsers.filter(teamUser => teamUser[0].id === team._id).map((teamUser: any) => (
                                    <div className='label-container' key={teamUser.id}>
                                        {teamUser.map((user: any) => (
                                            <div className='labels-container' key={user.id}>
                                                {user.users.slice(0, 10).map((user: any, index: number) => (
                                                    <p className='label' key={index}>{user.name}</p>
                                                ))}
                                                {user.users.length > 10 && (
                                                    <p className='label'>+ {user.users.length - 10}</p>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div> */}
        </div>
    );
};

export default ChatScreen;
