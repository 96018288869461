import React, { useEffect, useState } from 'react';
import paymentService from '../services/PaymentService';
import { useTranslation } from 'react-i18next';


const PaimentsNotification: React.FC = () => {
    const token = localStorage.getItem('token') as string;
    const [paiments, setPaiments] = useState<any[]>([]);
    const companyId = localStorage.getItem('companyId');
    const {t} = useTranslation();


    const fetchPaiments = async () => {
        try{
            const response = await paymentService.getPaymentWithCompanyId(token,companyId);
            setPaiments(response);
            console.log('Paiments loaded :', response);
        } catch (error) {
            console.error('Error fetching paiments:', error);
        }
    }

    useEffect(() => {
        if(!token) return;
        fetchPaiments();
    }, [token, companyId]);




    return (
        paiments && paiments.length > 0 ? (
            <div className='paiment-pending paiment-error'>
                <a className="link-payment" href="/pay">{t("paiments-en-attente")}</a>
            </div>
        ) : null
    );
};

export default PaimentsNotification;