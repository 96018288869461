import axios from "axios";
let BASE_URL: any;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    BASE_URL = process.env.REACT_APP_API_URL_DEV;
} else {
    BASE_URL = process.env.REACT_APP_API_URL_PROD;
}

const UserService = {
    
    async getUser(token: string) {
        try {
            const response = await axios.get(`${BASE_URL}/api/users/user/`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            return response.data;
        } catch (error) {
            console.error('Error fetching agenda:', error);
        }
    },

    async updateUser(token: string, user: any) {
        try {
            const response = await axios.post(
                `${BASE_URL}/api/users/update/`, 
                { user },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
    
            return response.data;
        } catch (error) {
            console.error('Error updating user:', error);
        }
    },

    async getStripeInfo(token: any) {
        try {
            const response = await axios.get(`${BASE_URL}/api/users/user/getStripeInfo`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            return response.data;
        } catch (error) {
            console.error('Error fetching agenda:', error);
        }
    },

    async updateStripeInfo(token: string, info: any) {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const bodyParameters = {
                info:info
            };
            const response = await axios.post(`${BASE_URL}/api/users/user/updateStripeInfo`,bodyParameters, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
      },


    async createSetupIntent(token: string) {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const bodyParameters = {
            };
            const response = await axios.post(`${BASE_URL}/api/users/user/createSetupIntent`,bodyParameters, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
      },

      async setDefaultPaymentMethod(token: string, paymentMethodId:string) {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const bodyParameters = {
                paymentMethodId:paymentMethodId
            };
            const response = await axios.post(`${BASE_URL}/api/users/user/setDefaultPaymentMethod`,bodyParameters, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
      },


      async deletePaymentMethod(token: string, paymentMethodId:string) {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const bodyParameters = {
                paymentMethodId:paymentMethodId
            };
            const response = await axios.post(`${BASE_URL}/api/users/user/deletePaymentMethod`,bodyParameters, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
      },


      async getPaymentMethods(token: any) {
        try {
            const response = await axios.get(`${BASE_URL}/api/users/user/getPaymentMethods`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            return response.data;
        } catch (error) {
            console.error('Error fetching agenda:', error);
        }
    },


};

export default UserService;
