import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import React, { useEffect, useState } from 'react';
import PaymentService from '../services/PaymentService';
import '../BuyScreen.css';
import UserService from '../services/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faFileInvoice, faWallet } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import Popup from '../components/Popup';


const TermsScreen = () => {
 
  return (
    <div className="container">
     <div>
  <h1>Conditions Générales de Vente (CGV) - TEAMUPP au 18 février 2024</h1>

  <h2>Article 1. PRÉAMBULE</h2>
  <p>
    La société TEAMUPP, Société à Responsabilité Limitée (SARL) au capital de
    2000 euros, immatriculée au Registre du Commerce et des Sociétés de Lyon
    sous le numéro 921 342 150, dont le siège social est situé 16 rue Lavoisier,
    69003 Lyon, propose des services destinés aux entreprises et
    professionnels.
  </p>
  <p>
    Les présentes Conditions Générales de Vente (CGV) définissent les modalités
    et conditions d'utilisation des services proposés par TEAMUPP, ainsi que les
    droits et obligations des Parties.
  </p>

  <h2>Article 2. DÉFINITIONS</h2>
  <ul>
    <li>
      <strong>Services ponctuels :</strong> Prestations uniques, comme les
      ateliers ou services de bien-être réalisés sur site pour le compte du
      Client.
    </li>
    <li>
      <strong>Offres "one shot" :</strong> Services spécifiques via l’application
      (exemple : challenges ponctuels d’une durée limitée, comme un challenge
      sportif de 3 semaines).
    </li>
    <li>
      <strong>Abonnement :</strong> Contrat donnant accès à des services
      récurrents sur l’application, pour une durée minimale de 12 mois,
      renouvelable automatiquement.
    </li>
    <li>
      <strong>Client :</strong> Toute personne morale ayant souscrit à une offre
      de service auprès de TEAMUPP.
    </li>
    <li>
      <strong>Devis :</strong> Document décrivant les services choisis par le
      Client, validé par sa signature.
    </li>
    <li>
      <strong>Prestataire de paiement :</strong> Stripe, utilisé par TEAMUPP pour
      la gestion des paiements en ligne.
    </li>
    <li>
      <strong>Espace client :</strong> Plateforme accessible via
      <a href="https://dashboard.teamupp.fr" target="_blank" rel="noopener noreferrer">
        https://dashboard.teamupp.fr
      </a>
      , permettant au Client de gérer son compte, ses services, et ses paiements.
    </li>
    <li>
      <strong>Bénéficiaire :</strong> Salarié ou client final bénéficiant des
      prestations proposées.
    </li>
  </ul>

  <h2>Article 3. CHAMP D’APPLICATION</h2>
  <p>
    Les présentes CGV s’appliquent à toutes les prestations, abonnements, et
    offres "one shot" proposés par TEAMUPP. Toute commande implique l’acceptation
    pleine et entière des CGV par le Client. En cas de conflit avec des
    conditions générales d’achat du Client, les CGV de TEAMUPP prévalent.
  </p>

  <h2>Article 4. COMMANDES ET SOUSCRIPTION</h2>
  <h3>4.1 Processus de commande</h3>
  <ul>
    <li>
      <strong>Services ponctuels :</strong> Les services ponctuels (massages,
      bien-être) font l’objet d’un devis, transmis par email ou via l’espace
      client. La commande est validée à réception du devis signé avec la mention
      "Bon pour accord".
    </li>
    <li>
      <strong>Offres "one shot" et abonnements :</strong> Ces services sont
      souscrits directement depuis l’espace client, accessible à
      <a href="https://dashboard.teamupp.fr" target="_blank" rel="noopener noreferrer">
        https://dashboard.teamupp.fr
      </a>
      .
    </li>
  </ul>
  <h3>4.2 Validation de la commande</h3>
  <ul>
    <li>Réception d’un devis signé (pour les services ponctuels).</li>
    <li>
      Validation du paiement en ligne via Stripe (pour les offres "one shot" et
      abonnements).
    </li>
  </ul>
  <p>
    TEAMUPP se réserve le droit de demander des garanties financières ou de
    refuser une commande si la situation du Client le justifie.
  </p>

  <h2>Article 5. LICENCE ET MODALITÉS D’EXÉCUTION DES SERVICES</h2>
  <h3>5.1 Périmètre de la Licence</h3>
  <p>
    TEAMUPP accorde au Client une Licence non-exclusive, non-transférable, et
    limitée dans le cadre de l’utilisation des Services. Cette Licence est
    strictement réservée à des fins professionnelles internes pour la durée
    précisée dans le Contrat.
  </p>
  <p>
    Le Client est autorisé à permettre à ses Utilisateurs identifiés d’accéder
    aux Services dans les limites prévues par les CGV.
  </p>
  <h3>5.2 Restrictions</h3>
  <p>Le Client s’engage à ne pas :</p>
  <ul>
    <li>Sous-licencier, louer, vendre ou transférer tout ou partie des Services.</li>
    <li>
      Modifier, adapter, traduire ou créer des œuvres dérivées des Services ou de
      leur composant.
    </li>
    <li>
      Réaliser de l’ingénierie inverse ou tenter d’accéder au code source, sauf
      dispositions contraires prévues par la loi.
    </li>
    <li>
      Utiliser les Services pour nuire à leur intégrité ou à celle des autres
      utilisateurs.
    </li>
    <li>Permettre l’accès non autorisé aux Services.</li>
  </ul>
  <h3>5.3 Services digitaux et évolutions</h3>
  <p>
    Les Services fournis incluent des améliorations régulières. Certaines
    nouvelles fonctionnalités peuvent nécessiter des frais supplémentaires ou
    des conditions particulières.
  </p>
  <h3>5.4 Modalités d’exécution des Services</h3>
  <ul>
    <li>
      <strong>Services ponctuels :</strong> Les prestations de bien-être sont
      fournies aux dates et horaires convenus avec le Client, selon le devis
      accepté.
    </li>
    <li>
      <strong>Services digitaux :</strong> Les offres "one shot" et abonnements
      sont accessibles via l’espace client après validation du paiement.
    </li>
  </ul>

  <h2>Article 6. DURÉE, RÉTRACTATION, ET RÉSILIATION</h2>
  <h3>6.1 Services ponctuels</h3>
  <p>
    Le Client peut annuler ou modifier une prestation en informant TEAMUPP par
    écrit au moins 24 heures avant la date prévue. En cas d’annulation tardive,
    TEAMUPP facturera jusqu’à 30 % du montant total prévu.
  </p>
  <h3>6.2 Offres "one shot"</h3>
  <p>
    Une fois le paiement validé dans l’espace client via Stripe, aucun
    remboursement ne sera effectué.
  </p>
  <h3>6.3 Abonnements annuels</h3>
  <p>
    Les abonnements sont conclus pour une durée initiale de 12 mois,
    renouvelable tacitement pour des périodes équivalentes.
  </p>
  <p>
    <strong>Résiliation :</strong> Le Client peut résilier par lettre
    recommandée avec accusé de réception, selon les préavis suivants :
  </p>
  <ul>
    <li>30 jours pour les contrats de 12 mois.</li>
    <li>60 jours pour les contrats de plus de 12 mois et jusqu’à 24 mois.</li>
    <li>90 jours pour les contrats supérieurs à 24 mois.</li>
  </ul>

  <h2>Article 7. CONDITIONS TARIFAIRES</h2>
  <h3>7.1 Tarifs et facturation</h3>
  <ul>
    <li>
      Les tarifs des services ponctuels sont définis dans le devis. Le paiement
      s’effectue par virement bancaire, à réception de la facture.
    </li>
    <li>
      Les offres "one shot" et abonnements sont payables en ligne via Stripe, par
      carte bancaire ou prélèvement SEPA, depuis l’espace client.
    </li>
  </ul>
  <h3>7.2 Retards de paiement</h3>
  <ul>
    <li>Une indemnité forfaitaire de 40 euros pour frais de recouvrement.</li>
    <li>Des intérêts de retard au taux légal majoré de 10 points.</li>
  </ul>

  <h2>Article 8. RESPONSABILITÉ</h2>
  <p>
    TEAMUPP s’efforcera de fournir ses services dans des conditions optimales.
    Toutefois, sa responsabilité ne pourra être engagée en cas de :
  </p>
  <ul>
    <li>Manquement du Client à ses obligations.</li>
    <li>Force majeure.</li>
    <li>Dysfonctionnements externes à TEAMUPP (ex. : problèmes de connexion).</li>
  </ul>

  <h2>Article 9. DONNÉES ET CONFIDENTIALITÉ</h2>
  <p>
    Les données collectées sont traitées conformément à la réglementation en
    vigueur. Les Parties s’engagent à préserver la confidentialité des
    informations échangées dans le cadre du Contrat.
  </p>

  <h2>Article 10. RÉSILIATION POUR FAUTE</h2>
  <p>
    En cas de manquement grave de l’une des Parties (non-paiement, violation des
    droits de propriété intellectuelle, etc.), l’autre Partie pourra résilier le
    contrat de plein droit après mise en demeure restée sans effet sous 30 jours.
  </p>

  <h2>Article 11. DISPOSITIONS FINALES</h2>
  <p>
    Si une clause des CGV est déclarée nulle, les autres clauses demeurent
    applicables.
  </p>

  <h2>Article 12. LOI APPLICABLE ET LITIGES</h2>
  <p>
    Les présentes CGV sont soumises au droit français. Tout litige sera soumis à
    la compétence exclusive des tribunaux de Lyon.
  </p>
</div>

    </div>
  );
};

export default TermsScreen;

