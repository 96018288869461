import axios from "axios";
let BASE_URL: any;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    BASE_URL = process.env.REACT_APP_API_URL_DEV;
} else {
    BASE_URL = process.env.REACT_APP_API_URL_PROD;
}

const PaymentService = {

    async getPayments(token: string) {
        try {
            const response = await axios.get(`${BASE_URL}/api/payments/getPayments`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            return response.data;
        } catch (error) {
            console.error('Error fetching agenda:', error);
        }
    },

    async payPayment(token: string, paymentId: any) {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const bodyParameters = {
                paymentId:paymentId
            };
            const response = await axios.post(`${BASE_URL}/api/payments/payPayment`,bodyParameters, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
      },

      async getPaymentWithCompanyId(token: string, companyId: any) {
        try {
            const response = await axios.get(`${BASE_URL}/api/payments/getPayment/${companyId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            return response.data;
        } catch (error) {
            console.error('Error fetching agenda:', error);
        }
    },


}

export default PaymentService;
