import React, { useEffect, useState } from 'react';
import PaymentMethods from './PaymentMethod';
import BuyScreen from './BuyScreen';
import CustomerForm from './CustomerFormScreen';
import '../Tabs.css'; // Pour ajouter des styles spécifiques aux onglets
import Header from '../components/Header';
import PaymentService from '../services/PaymentService';
import { useTranslation } from 'react-i18next';
import Popup from '../components/Popup';

const PayInvoice = () => {
  const [activeTab, setActiveTab] = useState('billingAddress');
  const token = localStorage.getItem('token') as string; 
  const companyId = localStorage.getItem('companyId');
  const [paiments, setPaiments] = useState<any[]>([]);
  const { t } = useTranslation();
  const [isBillingAddressComplete, setIsBillingAddressComplete] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fetchPaiments = async () => {
      try {
        const response = await PaymentService.getPaymentWithCompanyId(token, companyId);
        
        const unpaidPaymentsCount = response?.filter(
          (payment:any) => payment.status === 'unpaid' || payment.status === 'pending'
        ).length;
  
        setPaiments(unpaidPaymentsCount);
        console.log('Unpaid/Pending Payments loaded:', unpaidPaymentsCount);
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    };
  
    if (!token) return;
    fetchPaiments();
  }, [token, companyId]);

  
  const handleBillingStatusUpdate = (status: boolean) => {
    setIsBillingAddressComplete(status);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'paymentMethods':
        return <PaymentMethods />;
      case 'payments':
        return <BuyScreen />;
      case 'billingAddress':
        return <CustomerForm onBillingStatusUpdate={handleBillingStatusUpdate}/>;
      default:
        return <PaymentMethods />;
    }
  };
  
  const onClickBtn = (info:string) => {
    if(!isBillingAddressComplete) {
      if(info != 'billingAddress') {
        setPopupMessage('Veuillez compléter votre adresse de facturation.');
        setShowPopup(true);
      }

    } else {
      setActiveTab(info)
    }

  }

  return (
    <div>
      <div className='container'>
        <Header title={t("paiements")}/>
      </div>
    <div className="container-tabs">
      <div className="tabs">
      <button
          className={activeTab === 'billingAddress' ? 'paymentButtonNavActive' : 'paymentButtonNav'}
          onClick={() => onClickBtn('billingAddress')}
        >
          {t("adresse-de-facturation")}
        </button>
        <button
          className={activeTab === 'paymentMethods' ? 'paymentButtonNavActive' : 'paymentButtonNav'}
          onClick={() => onClickBtn('paymentMethods')}
        >
          {t("moyens-de-paiement")}
        </button>
        <button
          className={activeTab === 'payments' ? 'paymentButtonNavActive' : 'paymentButtonNav'}
          onClick={() => onClickBtn('payments')}
        >
          {t("paiements")} {paiments ? <span className='paiment-bulles-notification'>{paiments}</span> :<span className='paiment-bulles-notification'>{0}</span> }
        </button>
       
      </div>
      {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}

      <div className="tab-content">{renderContent()}</div>
    </div>
    </div>
  );
};

export default PayInvoice;

