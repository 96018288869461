import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import '../SetupForm.css'; // Assurez-vous d'importer le fichier CSS
import { useTranslation } from 'react-i18next';

const SetupForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string | null>(null);
  const [isProcessing, setProcessing] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);  // État pour suivre l'acceptation des CGU

  const { t } = useTranslation();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      setMessage("Stripe ou Elements n'est pas encore prêt.");
      return;
    }

    setProcessing(true);

    // Confirmer le SetupIntent pour enregistrer la méthode de paiement
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // Pas besoin de return_url ici
      },
      redirect: 'if_required',
    });

    if (error) {
      setMessage(error.message || "Une erreur est survenue.");
      setProcessing(false);

    } else {
      window.location.reload();

      setMessage(null);
      setProcessing(false);
      onSuccess(); // Appeler le succès après l'ajout réussi du moyen de paiement
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div style={{marginTop:12, marginBottom:15}}>
        <input
          type="checkbox"
          id="terms"
          checked={termsAccepted}
          onChange={() => setTermsAccepted(!termsAccepted)}  // Met à jour l'état lors de la sélection
        />
        <label htmlFor="terms" >
          {t("lu-et-accepte")}{' '}
          <a href="/terms" target="_blank" rel="noopener noreferrer">
            {t("les-cgu")}
          </a>
        </label>
      </div>
      <button
        className={`submit-button ${!termsAccepted ? 'disabled' : ''}`}
        disabled={!termsAccepted || isProcessing}
      >
        {isProcessing ? t("traitement-en-cours") : t("ajouter-le-moyen-de-paiement")}
      </button>
      {message && <div className="error-message">{message}</div>}
    </form>
  );
};

export default SetupForm;
